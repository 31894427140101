import React from 'react'
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from 'mdbreact'

import webicon from '../images/webdev-icon.png'
import wpicon from '../images/wordpress-icon.png'
import appicon from '../images/appdev-icon.png'

const FeaturesPage = () => {
  return (
    <MDBContainer tag="section" className="pt-md-5 pt-lg-2 my-5">
      <MDBRow>
        <MDBCol>
          <h2 className="h1-responsive font-weight-light mt-3">
            What's on <br />
            the menu?
          </h2>
          <hr class="big-strike mt-0" />
          <p className="lead grey-text w-responsive mb-5 d-none">
            We specialise in building websites for small business.
          </p>
        </MDBCol>
        <MDBCol>
          <MDBRow>
            <MDBCol md="6">
              {/* <MDBIcon icon="desktop" size="3x" className="red-text" /> */}
              <hr />
              <img src={webicon} />
              <h5 className="font-weight-bold my-4">Web Design</h5>
              <p className="grey-text mb-md-0 mb-5">
                Improve your reach and capture new customers with a bespoke
                website for your business. We build everything from static
                landing pages to fully featured e-commerce stores.
              </p>
            </MDBCol>
            <MDBCol md="6">
              <hr />
              <img src={wpicon} />
              {/* <MDBIcon icon="mobile-alt" size="3x" className="cyan-text" /> */}
              <h5 className="font-weight-bold my-4">Wordpress</h5>
              <p className="grey-text mb-md-0 mb-5">
                Have an existing wordpress based website or online store? We can
                help you update the look and feel while sticking with a platform
                you’re familiar with.
              </p>
            </MDBCol>
            <MDBCol md="6">
              {/* <MDBIcon
                icon="map-marker-alt"
                size="3x"
                className="orange-text"
              /> */}
              <hr />
              <img src={appicon} />
              <h5 className="font-weight-bold my-4">Mobile Apps</h5>
              <p className="grey-text mb-md-0 mb-5">
                Could your business benefit from a mobile experiance? We build
                mobile apps for both Android and iOS.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default FeaturesPage
