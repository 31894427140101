import React from 'react'
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { Link } from 'gatsby'
import asphericFooterLogo from '../images/asphericlabs-footer-logo.svg'
import ContactForm from './contactForm'

const Footer = () => {
  const [showContactPanel, setShowContactPanel] = React.useState(false)
  const onClick = () => setShowContactPanel(!showContactPanel)
  return (
    <MDBFooter className="font-small pt-0">
      <div
        id="contactUs"
        class="d-flex contactUsFooter justify-content-center py-5"
      >
        <div class="col col-xs-12 col-lg-6 ">
          <div class="row justify-space-between">
            <div class="col col-auto">
              <h2>
                Lets talk about your <br />
                project.
              </h2>
            </div>
            <div class="col mt-3 text-right">
              <button
                class="btn btn-info text-white rounded-0 shadow-0 ml-"
                onClick={onClick}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div class="row mt-5 d-flex text-right collapse justify-content-center">
            {showContactPanel ? <ContactForm /> : null}
          </div>
        </div>
      </div>
      <div class="mainFooter row align-content-end justify-content-center">
        <div class="col-xl-6 col-lg-10 col-md-12">
          {/* <div class="col-md-12 col-lg-8"> */}
          <div class="row text-xs-center text-sm-center text-md-left">
            <div className="col-md-3 col-sm-12 pl-lg-0 pl-md-5 mt-4">
              <img src={asphericFooterLogo}></img>
            </div>
            {/* <div class="col-md-2 mt-4">
                <h5>Company</h5>
                <ul>
                  <li>
                    <a href="#!">Our Philosophy</a>
                  </li>

                  <li>
                    <a href="#!">Our Work</a>
                  </li>
                </ul>
              </div> */}
            <div className="col-md-3 col-sm-12 mt-4">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="/#aboutUs">About Us</a>
                </li>
                <li>
                  <Link to="/#clientWork">Portfolio</Link>
                </li>
                <li>{/* <a href="#!">Testimonials</a> */}</li>
              </ul>
            </div>
            <div md="2" className="col-md-3 col-sm-12 mt-4">
              <h5>Additional Info</h5>
              <ul>
                <li>{/* <a href="#!">Terms & Conditions</a> */}</li>
                <li>
                  <a href="#!">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div md="2" className="col-md-3 col-sm-12 mt-4">
              <h5>Get In Touch</h5>
              <ul>
                <li>
                  <a href="/#contactUs">Contact Us</a>
                </li>
                {/* <li class="mt-2">
                    <MDBIcon
                      fab
                      icon="facebook"
                      size="lg"
                      className="mx-1 white-text mr-md-2"
                    ></MDBIcon>
                    <MDBIcon
                      fab
                      icon="instagram"
                      size="lg"
                      className="mx-1 white-text mr-md-4"
                    ></MDBIcon>
                  </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mt-2">
          <hr className="white" style={{ width: '80%', opacity: '0.7' }} />
          <p class="pt-1 pb-1 grey-text">
            <b>&copy; {new Date().getFullYear()} Aspheric Pty Ltd</b>
          </p>
        </div>
      </div>
      {/* </div> */}
    </MDBFooter>
  )
}

export default Footer
