import React, { Component } from 'react'
import Layout from '../components/layout'
import Intro from '../components/mask'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import Portfolio from '../components/portfolio'
import { MDBRow } from 'mdbreact'
import headerImage from '../images/abstract-art1.webp'
import FullPageCover from '../components/fullPageCover'
class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Aspheric Digital Solutions | Web & Mobile Design, Sydney"
            description="Aspheric Digital Solutions is a Sydney based website design and development agency supporting businesses all over the globe."
            keywords={[
              `Aspheric`,
              `IT solutions`,
              `digital solutions`,
              `web design`,
              `web developer`,
              `website design`,
              `northern beaches`,
              `sydney`,
              `Wes Callaghan`,
            ]}
          />
          {/* <Carousel /> */}
          <FullPageCover />
          <Intro />
          <main>
            <span id="aboutUs"></span>
            <AboutSection />
            {/* <Portfolio /> */}
            {/* <span id="clientWork"></span> */}
            <section id="cardSection">
              <h2 className="h1-responsive text-center font-weight-bold mb-5">
                Client Work
              </h2>
              <Card />
            </section>
          </main>
        </Layout>
      </>
    )
  }
}

export default App
