import React from 'react'
import { MDBContainer, MDBBtn, MDBIcon, MDBRow, MDBCol } from 'mdbreact'
import CafeIso from '../images/cafeiso.svg'
import Afroman from '../images/Afroman.svg'
import PurpleDot from '../images/purpleDot.svg'
const Intro = ({ children }) => {
  return (
    <div class="bigHead" style={{ width: '100vw', height: '100%' }}>
      <div class="purpleDotContainer">
        {/* <div class="purpleDot"></div> */}
      </div>
      <div class="row" style={{ marginTop: '5vh' }}>
        <div md="12" className="text-center col">
          <div class="mask">
            <div class="container h-100">
              <div class="row  h-100">
                <div class="col-md-6">
                  <h1 id="tagline" class="mb-4 mt-5 text-left font-weight">
                    Beautiful work
                    <br />
                    by passionate
                    <br />
                    people.
                  </h1>
                  <p
                    id="p-line"
                    class="dark-grey-text text-left col-md-10 pl-0"
                  >
                    Aspheric digital solutions has been helping small business
                    move into the digital environment for over 5 years.
                  </p>
                  <div class="row ml-1"></div>
                </div>

                <div class="col-md-6">
                  <img src={Afroman} alt="" class="img-fluid"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
