import React, { Component } from 'react'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBFormInline,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  Collapse,
  MDBNavbarBrand,
} from 'mdbreact'
import { Link } from 'gatsby'
import CustomNavLink from './customLink'
import { ReactComponent as Logo } from '../images/light-bulb.svg'
import asphericLight from '../images/aspheric-logo.svg'
import '../stylesheets/main.css'
class NavbarPage extends Component {
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    let toggle
    if (!this.isOpen) {
      toggle = <i class="fa fa-bars" aria-hidden="true"></i>
    } else {
      toggle = <i class="fa fa-times" aria-hidden="true"></i>
    }
    return (
      <MDBNavbar className="navbar navbar-expand-md d-flex ml-auto mr-auto col-lg-9 col-md-12">
        {/* <div class="col-10 text-center"> */}
        <MDBNavbarBrand>
          <img class="header-logo" href="#" src={asphericLight}></img>
        </MDBNavbarBrand>

        {/* <div class="vl"></div> */}
        <MDBNavbarToggler class="navbar-toggler" onClick={this.toggleCollapse}>
          {toggle}
        </MDBNavbarToggler>

        <Collapse navbar isOpen={this.state.isOpen} id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto ml-4 text-center">
            <li class="nav-item active">
              <Link class="nav-link" href="#">
                Home
                <span class="sr-only">(current)</span>
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/#aboutUs">
                About Us
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/#clientWork">
                Client Work
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/#contactUs">
                Contact Us
              </Link>
            </li>
          </ul>
        </Collapse>
        {/* </div> */}
      </MDBNavbar>
    )
  }
}

export default NavbarPage
