import React from 'react'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
} from 'mdbreact'
import image from '../images/cafeole-thumb.jpg'
import JSONData from '../content/clientWork.json'
import Img from 'gatsby-image'

const CardExample = () => {
  return (
    <div class="row center justify-content-center">
      <div class="col-11 col-xl-9 col-lg-11 col-md-12">
        <div class="card-group">
          {JSONData.projects.map((data, index) => {
            return (
              <div class="col-12 col-md-6 col-lg-4 pt-4">
                <div class="card h-100" key={`projects_${index}`}>
                  <img class="card-img-top" src={data.image} alt="" />
                  <div class="card-body">
                    <h5 class="card-title">{data.title}</h5>
                    <p>{data.shortDescription}</p>
                    {/* <button
                  class="btn btn-info
                "
                  href={data.link}
                >
                  Visit Site
                </button> */}
                  </div>
                  <div class="card-footer">
                    <small class="text-muted">Project type: {data.type}</small>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CardExample
