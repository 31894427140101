import React from 'react'

const ContactForm = () => {
  return (
    <div class="col-11 my-5 text-right">
      <form
        method="POST"
        name="contact"
        netlify-honeypot="bot-field"
        data-netlify="true"
        id="contactForm"
        class="form contact-form"
        netlify
      >
        <input type="hidden" name="contact_form" value="contact" />
        <div class="row">
          <div class="col-xl-6 pr-xl-2">
            <input
              type="text"
              name="name"
              class="form-control mb-2 mr-sm-2 mb-3 rounded-0 shadow-0"
              placeholder="Full Name"
            />
            <input
              type="text"
              name="phoneNumber"
              class="form-control mb-2 mr-sm-2 mb-3 rounded-0 shadow-0"
              placeholder="Contact Number"
            />

            <input
              type="email"
              name="email"
              class="form-control py-0 rounded-0 mb-3 shadow-0"
              placeholder="Email"
            />
          </div>
          <div class="col-xl-6 pl-xl-2">
            <div class="form-group mb-3 ">
              {/* <label for="inputState">State</label> */}
              <select
                id="inputState"
                name="contactMethod"
                class="form-control rounded-0 shadow-0"
              >
                <option selected>How would you like to be contacted?</option>
                <option>Phone</option>
                <option>Email</option>
              </select>
            </div>
            <textarea
              class="form-control rounded-0 shadow-0 "
              type="text"
              name="message"
              placeholder="Tell us about your next project."
              rows="3"
            />
            <input type="hidden" name="bot-field" />
            <button
              type="SUBMIT"
              class="btn btn-info text-white rounded-0 shadow-0 mt-3 mr-0"
            >
              Go
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
